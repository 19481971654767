
/* Transitions for interactive elements */
.primary-button {
    background-color: var(--primary-color) !important; /* Override Bootstrap */
    border-color: var(--primary-color) !important;     /* Override Bootstrap */
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.primary-button:hover {
    background-color: var(--primary-hover) !important; /* Override Bootstrap */
    border-color: var(--primary-hover) !important;     /* Override Bootstrap */
}