/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* CSS Variables for Theme Management */
:root {
    --primary-color: #594f36;        /* Mellow Brown */
    --primary-hover: #7b6651;        /* Slightly Lighter Brown */
    --card-bg: rgba(253, 246, 227, 0.9); /* Light Parchment with transparency */
    --card-border: #ffefd5;          /* Soft Beige */
    --heading-color: #594f36;        /* Mellow Brown for headings */
    --text-color: #594f36;           /* Dark Text */
    --intro-text-color: #594f36;     /* Mellow Brown for intro text */
    --background-overlay: rgba(0, 0, 0, 0.3); /* Dark overlay for better text contrast */
    --dropdown-bg: #fdf6e3;           /* Dropdown background */
    --dropdown-border: #594f36;       /* Dropdown border */
    --dropdown-text: #594f36;         /* Dropdown text */
    --disclaimer-color: #594f36;      /* Disclaimer text color */
    --link-color: #594f36;            /* Link color */
    --link-hover: #7b6651;            /* Link hover color */
}

/* Global Styles */
html, body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    color: var(--text-color);
    min-height: 100vh;
    overflow-x: hidden;
    position: relative;
}

/* Background image as a pseudo-element */
body::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: url('../public/background.jpg') repeat center center;
    background-size: cover;
    z-index: -1; /* Ensures it stays behind all content */
    pointer-events: none; /* Prevents interactions with the background */
}

/* For iOS compatibility */
@supports (-webkit-touch-callout: none) {
    body::before {
        background-attachment: scroll; /* Prevents iOS stretching */
    }
}


/* Dark Overlay for Background */
body::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--background-overlay);
    z-index: -1;
}

.app-container {
    padding: 2rem 0;
    min-height: 100vh; /* Ensure it covers the viewport height */
    position: relative;
    z-index: 1; /* Stack above the overlay */
}


/* Main Card Styling */
.main-card {
    background-color: var(--card-bg); /* Using existing color variable */
    border-radius: 15px;
    padding: 2rem;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Slightly larger shadow for emphasis */

    /* Entrance Animation */
    opacity: 0;
    transform: scale(0.95); /* Start slightly smaller */
    animation: fadeInScale 0.3s ease-out forwards; /* Adjust duration and timing function as needed */
}

/* Keyframes for fade-in and scale-up animation */
@keyframes fadeInScale {
    from {
        opacity: 0;
        transform: scale(0.95); /* Start smaller */
    }
    to {
        opacity: 1;
        transform: scale(1); /* End at full size */
    }
}


/* Logo Styling */
.logo-img {
    width: 100%;
    height: auto;
}

/* Introduction Text Styling */
.intro-text {
    font-size: 1.1rem;
    color: var(--intro-text-color);
    text-align: center;
    margin-bottom: 1.5rem;
    line-height: 1.5;
}

/* Generate Button Styling */
.generate-button {
    background-color: var(--primary-color) !important; /* Override Bootstrap */
    border-color: var(--primary-color) !important;     /* Override Bootstrap */
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease;
    color: #fff !important;                            /* Ensure text is white */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);          /* Subtle shadow */
}

.generate-button:hover {
    background-color: var(--primary-hover) !important; /* Override Bootstrap */
    border-color: var(--primary-hover) !important;     /* Override Bootstrap */
}

/* Styled Age Selection Label */
.age-label {
    color: var(--primary-color);
    font-weight: 700; /* Bold for emphasis */
}

/* Toggle Button Styling */
.toggle-button {
    background-color: var(--primary-color) !important; /* Override Bootstrap */
    border-color: var(--primary-color) !important;     /* Override Bootstrap */
    font-weight: 700;            /* Bold text for emphasis */
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
    color: #fff !important;
    background-color: transparent; /* Ensure transparent background if using outline variant */
    border-radius: 5px;          /* Rounded corners */
    padding: 0.5rem 1rem;        /* Padding for better click area */
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
}

.toggle-button:hover {
    background-color: var(--primary-hover) !important; /* Override Bootstrap */
    color: #fff !important;                            /* Change text color on hover */
    text-decoration: none;                  /* Remove underline if any */
}

/* Ensure Icons Inherit the Text Color */
.toggle-button svg {
    margin-right: 0.5rem; /* Add space between icon and text */
    fill: currentColor;    /* Inherit the current text color */
}

/* Additional Inputs Styling */
.additional-inputs {
    padding: 1rem;
    border: 1px solid var(--dropdown-border);
    border-radius: 5px;
    background-color: var(--dropdown-bg);
    margin-bottom: 1rem;
}

.additional-inputs .form-label {
    color: var(--primary-color);
    font-weight: 600;
}

.additional-inputs .form-control {
    border-color: var(--dropdown-border);
}

.additional-inputs .form-control:focus {
    border-color: var(--primary-hover);
    box-shadow: none; /* Remove default Bootstrap focus shadow */
}

/* Story Display Styling */
.story-card {
    background-color: var(--card-bg);
    border: 2px solid var(--card-border);
    border-radius: 10px;
    animation: fadeIn 1s ease-in-out;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    user-select: none;
}

.story-card-header {
    background-color: var(--primary-color);
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
    border-bottom: 1px solid var(--card-border);
    color: #fff;
    padding: 0.75rem 1rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.story-card-body {
    padding: 1rem 1.5rem;
    color: var(--text-color);
    font-size: 1.5rem;
    line-height: 1.6;
    font-family: 'Roboto', sans-serif;
}

/* Disclaimer Styling */
.disclaimer {
    font-size: 0.9rem;
    color: var(--disclaimer-color);
    text-align: center;
    margin-top: 1rem;
}

.disclaimer a {
    color: var(--link-color);
    text-decoration: none;
}
a{
    text-decoration: none !important;
}

.disclaimer a:hover {
    color: var(--link-hover);
    text-decoration: underline;
}

/* Fade-In Animation */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

/* Responsive Adjustments */
@media (max-width: 576px) {
    .intro-text {
        font-size: 1rem;
    }
    .main-card{
        width: 100%;
        padding: 5%;
    }

    .generate-button {
        font-size: 1rem;
    }

    .story-card-body {
        padding: 0rem 0.5rem;
        font-size: 1.1rem;
    }

    .disclaimer {
        font-size: 0.8rem;
    }
    .peida-logo{
        display: none;
    }
}

/* Additional Styles for Privacy Policy Link */
a[href="/privacy-policy"] {
    color: var(--link-color);
    text-decoration: underline;
}

a[href="/privacy-policy"]:hover {
    color: var(--link-hover);
    text-decoration: underline;
}


/* Remove text decoration and default link styles */
.toggle-button a {
    text-decoration: none;
    color: inherit;
}

/* **New Additions Start Here** */

/* Form Input Styles */
.main-card .form-label {
    color: var(--primary-color);
    font-weight: 600;
}

.main-card .form-control {
    border-color: var(--dropdown-border);
    border-radius: 5px;
    transition: border-color 0.3s ease;
}

.main-card .form-control:focus {
    border-color: var(--primary-hover);
    box-shadow: none; /* Remove default Bootstrap focus shadow */
}

/* Alert Styling within main-card */
.main-card .alert {
    border-radius: 5px;
    font-weight: 500;
}

/* Links within main-card */
.main-card a {
    color: var(--link-color);
    text-decoration: underline;
    transition: color 0.3s ease;
}

.main-card a:hover {
    color: var(--link-hover);
    text-decoration: none;
}

/* Typography within main-card */
.main-card h3 {
    color: var(--heading-color);
    font-weight: 700;
}

.main-card p {
    color: var(--text-color);
    line-height: 1.6;
}

/* Button Focus Styles */
.primary-button:focus, .generate-button:focus, .toggle-button:focus {
    background-color: var(--primary-color) !important; /* Override Bootstrap */
    border-color: var(--primary-color) !important;     /* Override Bootstrap */
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease;
    color: #fff !important;                            /* Ensure text is white */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);          /* Subtle shadow */
}

/* Transitions for interactive elements */
.primary-button, .toggle-button {
    background-color: var(--primary-color) !important; /* Override Bootstrap */
    border-color: var(--primary-color) !important;     /* Override Bootstrap */
    color: #fff !important;                            /* Ensure text is white */
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.primary-button:hover, .toggle-button:hover {
    background-color: var(--primary-hover) !important; /* Override Bootstrap */
    border-color: var(--primary-hover) !important;     /* Override Bootstrap */
    color: #fff !important;                            /* Ensure text is white */
}

.primary-button-2 {
    background-color: var(--primary-color) !important; /* Override Bootstrap */
    border-color: var(--primary-color) !important;     /* Override Bootstrap */
    color: #fff !important; /* Ensure text is white */
    margin-right: 15px;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.primary-button-2:hover {
    background-color: var(--primary-hover) !important; /* Override Bootstrap */
    border-color: var(--primary-hover) !important;     /* Override Bootstrap */
    color: #fff !important;                            /* Ensure text is white */
}

.past-stories-button {
    color: var(--primary-color) !important;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.past-stories-button:hover {
    color: var(--primary-hover) !important;                           /* Ensure text is white */
}

.navbar-brand {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--primary-color) !important;
}

/* Remove default link color */
.navbar-nav .nav-link {
    color: var(--text-color) !important;
    font-weight: 500;
}

/* Change link color on hover */
.navbar-nav .nav-link:hover {
    color: var(--primary-hover) !important;
}

/* Adjust the navbar background color if desired */
.navbar-light.bg-light {
    background-color: var(--dropdown-bg) !important;
}


/* Save Button Styling */
.save-button {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.save-button:disabled {
    opacity: 0.65;
    cursor: not-allowed;
}

/* Checkmark Icon Animation */
.save-button svg {
    transition: transform 0.3s ease;
    transform: scale(1);
}

.save-button:disabled svg {
    transform: scale(1.2);
}

.lisainfo{
    color: var(--intro-text-color) !important;
}

.logo-spacing {
    margin-bottom: 3rem !important; /* Adjust the value to add more or less space */
    margin-top: 3rem !important;
}

.navigation {
    background-color: rgba(255, 255, 255, 0.3); /* Semi-transparent white */
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Soft shadow */
}

/* Kohandatud stiilid küpsiste nõusoleku bannerile */
.cookie-consent-container {
    background: var(--card-bg) !important; /* Mellow Brown */
    color: var(--text-color) !important; /* Dark Text */
    font-size: 14px !important;
    padding: 10px 5px !important;
    text-align: center !important;
    position: fixed !important;
    bottom: 0 !important;
    width: 100% !important;
    z-index: 1000 !important;
}

.cookie-consent-button {
    background-color: var(--primary-hover) !important; /* Slightly Lighter Brown */
    color: var(--card-bg) !important; /* Light Parchment with transparency (as text color) */
    border: none !important;
    padding: 10px 20px !important;
    margin-left: 10px !important;
    border-radius: 5px !important;
    cursor: pointer !important;
    font-size: 14px !important;
}

.cookie-consent-decline {
    background-color: var(--dropdown-border) !important; /* Dropdown border color (pruun) */
    color: var(--card-bg) !important; /* Light Parchment with transparency (valge tekst) */
    border: none !important;
    padding: 10px 20px !important;
    margin-left: 10px !important;
    border-radius: 5px !important;
    cursor: pointer !important;
    font-size: 14px !important;
}

.cookie-consent-container a {
    color: var(--link-color) !important; /* Link color */
    text-decoration: underline !important;
}

.cookie-consent-container a:hover {
    color: var(--link-hover) !important; /* Link hover color */
}


/* Kohandatud stiilid PrivacyPolicy lehe jaoks */
.privacy-container {
    padding: 20px;
}

.privacy-card {
    background-color: var(--card-bg) !important;
    border: 1px solid var(--card-border) !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.privacy-title {
    color: var(--heading-color);
    margin-bottom: 20px;
}

.privacy-update {
    color: var(--text-color);
    margin-bottom: 30px;
}

.privacy-section-title {
    color: var(--heading-color);
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 1.5rem;
}

.privacy-section-text {
    color: var(--text-color);
    margin-bottom: 20px;
    font-size: 1rem;
    text-align: left;
}

.privacy-list {
    list-style-type: disc;
    padding-left: 20px;
    color: var(--text-color);
    margin-bottom: 20px;
}

.privacy-list li {
    margin-bottom: 10px;
}

.privacy-link {
    color: var(--link-color);
    text-decoration: underline;
}

.privacy-link:hover {
    color: var(--link-hover);
}

/* Kohandatud stiilid PrivacyPolicy lehe jaoks lõpus */
.privacy-card a {
    color: var(--link-color);
    text-decoration: underline;
}

.privacy-card a:hover {
    color: var(--link-hover);
}