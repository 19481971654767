.success-card {
    max-width: 400px;
    width: 100%;
    border: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.5s ease-in-out;
}

.success-icon {
    animation: popIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes popIn {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    60% {
        transform: scale(1.1);
        opacity: 1;
    }
    100% {
        transform: scale(1);
    }
}
